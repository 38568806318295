
.mainFrameProofOfWork{
    width: 92%;
    // border-radius: 6px;
    background-color: white;
    padding: 24px;
    margin: auto;
    // height: 80vh;
    font-family: "Roboto";

    .title{
        // padding-top: 20px;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        line-height: 32px;
    }
}


.iconSize{
    width: 100px;
    height: 100px;
}

.parerTEST{
    width: 100%;
}