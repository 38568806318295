
.videoDiv{
    padding-top: 20px;
    padding-bottom: 20px;

}

.videoStyling{
    border-radius: 6px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}