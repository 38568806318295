.apikeyWrapper{
    display: flex;
    .apiButton{
        height: 18%;
        margin: auto;
        width: 30%;
        min-width: 200px;
        max-width: 200px;
    }
    .emailButton{
        display: none;
    }
     label{
         padding: 24px;
     }
}
.removeApiKeyBtn{
    height: 50%;
    top: 23px;
}
.deleteButton{
    position:absolute; 
    bottom:0;
}
.apikeyWrapperPasswordChange{
    width: 95%;
    display: flex;
    .apiButton{
        height: 18%;
        margin: auto;
        width: 30%;
        margin-right: 16px;
        min-width: 200px;
        max-width: 200px;
    }
    .emailButton{
        display: none;
    }
    label{
        padding: 24px;
    }
}

.Chip{
    padding: 15px;
}