button {
  &.submit {
    margin-top: 8px;
  }
}
.main{
  overflow: scroll;
}

.paddingTop {
  padding-top: 30px;
  padding-bottom: 10px;
}
.iconAlignment {
  vertical-align: text-top;
}
