
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap');


.stickyHeader {
    top: 0px;
    left: auto;
    right: 0;
    position: sticky;
    z-index: 999;
}

.toolbar{
    max-height: 20px;
}

// @font-face {
//     font-family: 'unborneditrion';
//     font-style: normal;
//     font-weight: normal;
//     src: local('unborneditrion'), url('../../Fonts/unborneditrion.ttf') format('ttf');
// }

.font{
    font-family: 'Rajdhani';
    font-size: 20px;
    font-weight: 500;
}
