*{
    box-sizing: border-box;
  }
  @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500&display=swap');
  // @font-face {
  //   font-family: 'unborneditrion';
  //   font-style: normal;
  //   font-weight: normal;
  //   src: local('unborneditrion'), local('unborneditrion'),
  //    url('../../Fonts/unborneditrion.ttf') format('truetype'), /* Safari, Android, iOS */
  //    url('../../Fonts/unborneditrion.woff') format('woff'), /* Modern Browsers */
  // }

  .TEST{
    font-family: 'Rajdhani';
    font-weight: 400;
  }  
  
  .v-header{
    display:flex;
    align-items:center;
    color:#fff;
  }
  
  .container{
    max-width:1700px;
    padding-left:1rem;
    padding-right:1rem;
    margin:auto;
    text-align:center;
  }
  

  
  .header-overlay{
    height:100vh;
    position: absolute;
    top:0;
    left:0;
    width:100vw;
    z-index:1;
    background:#225470;
    opacity:0.6;
  }
  
  .header-content{
    z-index:2;
  }
  
  .header-content h1{
    font-size:50px;
    margin-top: 8%;
    margin-bottom:0;
  }
  
  .header-content p{
    font-size: 14px;
    display:block;
    width: 60%;
    margin: auto;
    padding-bottom:2rem;
  }
  
  .btn{
    background: #34b3a0;
    color:#fff;
    font-size:1.2rem;
    padding: 1rem 2rem;
    text-decoration: none;
  }
  
  .section{
    padding:20px 0;
  }
  
  .section-b{
    background:#333;
    color:#fff;
  }
  
  @media(max-width:960px){
    .container{
      padding-right:3rem;
      padding-left:3rem;
    }
  }