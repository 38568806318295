  .MuiPaper-root  {
      &.MuiPaper-rounded{

          border-radius: 0px 0px 10px 10px ;
      }
  }

  .snackBarMarginBottom{
      margin-bottom: 10vh;
  }


