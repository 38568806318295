
@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap');




.App {
  /* text-align: center; */
  background-color: whitesmoke;
  max-width: 2150px;
  min-width: 1320px;
  margin:  auto;
  .divider{
    margin-top: 200px;
    width: 80%;
  }
  .home{
    min-height: calc(99vh - 290px);
  }

}
.fullscreen-video-wrap{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
  
}

.blurVideo{
  filter: blur(8px);
  -webkit-filter: blur(15px);
  // background:#225470;

}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  // z-index: 3;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
    height: 70px;
  }
}

.App-header {
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FooterDiv{
  width: 100%;
  color: white;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html {
  overflow-y:none;
}


.footerContainer{
  font-family: "Roboto";
  font-size:10px;
  left: 0;
  bottom: 0;
  width: 40%;
  margin: auto;
  position: relative;
  text-align: center;
  .copyRight{
    padding-top: 10px;
    font-family: "Roboto";
    font-size:10px;
  }
    .footer{
      display: flex;
      padding: 12px 16px 24px;
      padding-left: calc(50% - 800px);
      padding-right: calc(50% - 800px);
      .flex{
        align-items: center;
        display: flex;
        margin: auto;
        .App-logo-footer{
          height: 24px;
        }
      }
    }
}

.marginLeft{
  margin-left: 16px;
}
