@import url(https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App{background-color:whitesmoke;max-width:2150px;min-width:1320px;margin:auto}.App .divider{margin-top:200px;width:80%}.App .home{min-height:calc(99vh - 290px)}.fullscreen-video-wrap{position:fixed;top:0;left:0;width:100%;height:100vh}.blurVideo{filter:blur(8px);-webkit-filter:blur(15px)}.background{position:fixed;top:0;left:0;right:0;bottom:0;overflow:hidden}.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 10s linear;animation:App-logo-spin infinite 10s linear;height:70px}}.App-header{padding-top:20px;padding-bottom:10px;display:flex;flex-direction:column;align-items:center;justify-content:center}.FooterDiv{width:100%;color:white;text-align:center}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}html{overflow-y:none}.footerContainer{font-family:"Roboto";font-size:10px;left:0;bottom:0;width:40%;margin:auto;position:relative;text-align:center}.footerContainer .copyRight{padding-top:10px;font-family:"Roboto";font-size:10px}.footerContainer .footer{display:flex;padding:12px 16px 24px;padding-left:calc(50% - 800px);padding-right:calc(50% - 800px)}.footerContainer .footer .flex{align-items:center;display:flex;margin:auto}.footerContainer .footer .flex .App-logo-footer{height:24px}.marginLeft{margin-left:16px}

.apikeyWrapper{display:flex}.apikeyWrapper .apiButton{height:18%;margin:auto;width:30%;min-width:200px;max-width:200px}.apikeyWrapper .emailButton{display:none}.apikeyWrapper label{padding:24px}.removeApiKeyBtn{height:50%;top:23px}.deleteButton{position:absolute;bottom:0}.apikeyWrapperPasswordChange{width:95%;display:flex}.apikeyWrapperPasswordChange .apiButton{height:18%;margin:auto;width:30%;margin-right:16px;min-width:200px;max-width:200px}.apikeyWrapperPasswordChange .emailButton{display:none}.apikeyWrapperPasswordChange label{padding:24px}.Chip{padding:15px}

.warningDiv{width:50%;margin:auto;margin-top:auto;text-align:center;margin-top:10px}

.LeagueOfLegends{width:92%;background-color:white;padding:24px;margin:auto;font-family:"Roboto"}.LeagueOfLegends .title{font-size:20px;font-weight:500;font-style:normal;line-height:32px}.paper{display:flex;flex-direction:column;align-items:center}.avatar{margin:8px;margin-top:24px;background-color:#dc0007 !important}.form{width:'100%';margin-top:spacing(1)}.submit{margin:spacing(3, 0, 2)}.hidePasswordWrongText p{display:none}.createAccountWrapper{background-color:white;height:90vh;width:92%;margin:auto}

@use '@material/button/mdc-button';@use '@material/button';.wrapper{display:flex;color:#444;width:92%;margin:auto}.wrapper .mdc-drawer{height:90vh;width:190px;position:-webkit-sticky;position:sticky;top:60px;overflow:hidden}.wrapper .mdc-drawer a:hover{background-color:whitesmoke;color:#3f51b5}.wrapper .mdc-drawer .cursorLink:hover{background-color:whitesmoke;color:#3f51b5;cursor:pointer}.wrapper .mdc-drawer span{margin-left:16px;font-size:14px}.wrapper .mdc-drawer .nav-list{padding:8px 0;list-style-type:none}.wrapper .mainFrame{width:100%;background-color:white;display:grid}.documentationContent{padding-left:16px;padding-right:64px;height:90vh;padding-bottom:64px;overflow:auto}.documentationContent p{font-size:14px}.documentationContent div{font-size:14px}.documentationContent h3{padding-top:8px}.blueColor.MuiButtonBase-root{color:#3f51b5}

a{cursor:pointer;color:blue;text-decoration:underline}

.centerImage{width:72%;margin:auto}.imageCss{width:700px}

.italic{font-family:"Roboto";font-style:italic}.italicUnderline{font-family:"Roboto";font-style:italic;text-decoration:underline}

.videoDiv{padding-top:20px;padding-bottom:20px}.videoStyling{border-radius:6px;box-shadow:0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.1)}

.breadcrumbDiv{background:#b0bec5;box-shadow:0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.1);padding-left:24px;height:30px;line-height:30px;margin:0px;border-radius:0px 0px 18px 18px;display:flex;align-items:center}.breadCrumbIcon.MuiSvgIcon-root{font-size:1em;margin-right:3px;vertical-align:middle}.MuiTypography-body1.lastElement{text-decoration:none;color:currentcolor;font-size:13px}.breadCrumbLink{text-decoration:none;color:white;font-size:13px;vertical-align:text-bottom}.innderText{margin:0px;margin-left:8px}.headerIcons{font-size:0.8em}

.stickyHeader{top:0px;left:auto;right:0;position:-webkit-sticky;position:sticky;z-index:999}.toolbar{max-height:20px}.font{font-family:'Rajdhani';font-size:20px;font-weight:500}

*{box-sizing:border-box}.TEST{font-family:'Rajdhani';font-weight:400}.v-header{display:flex;align-items:center;color:#fff}.container{max-width:1700px;padding-left:1rem;padding-right:1rem;margin:auto;text-align:center}.header-overlay{height:100vh;position:absolute;top:0;left:0;width:100vw;z-index:1;background:#225470;opacity:0.6}.header-content{z-index:2}.header-content h1{font-size:50px;margin-top:8%;margin-bottom:0}.header-content p{font-size:14px;display:block;width:60%;margin:auto;padding-bottom:2rem}.btn{background:#34b3a0;color:#fff;font-size:1.2rem;padding:1rem 2rem;text-decoration:none}.section{padding:20px 0}.section-b{background:#333;color:#fff}@media (max-width: 960px){.container{padding-right:3rem;padding-left:3rem}}

button.submit{margin-top:8px}.main{overflow:scroll}.paddingTop{padding-top:30px;padding-bottom:10px}.iconAlignment{vertical-align:text-top}

.mainFrameProofOfWork{width:92%;background-color:white;padding:24px;margin:auto;font-family:"Roboto"}.mainFrameProofOfWork .title{font-size:20px;font-weight:500;font-style:normal;line-height:32px}.iconSize{width:100px;height:100px}.parerTEST{width:100%}

.MuiPaper-root.MuiPaper-rounded{border-radius:0px 0px 10px 10px}.snackBarMarginBottom{margin-bottom:10vh}

