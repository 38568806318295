.LeagueOfLegends{
  width: 92%;
  // border-radius: 6px;
  background-color: white;
  padding: 24px;
  margin: auto;
  // height: 80vh;
  font-family: "Roboto";
  
  .title{
    // padding-top: 20px;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    line-height: 32px;
}
}

.paper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

   .avatar {
  margin: 8px ;
  margin-top: 24px;
  background-color:rgb(220, 0, 7) !important;
   }
    
.form {
  width: '100%';
  margin-top: spacing(1);
}
.submit {
  margin: spacing(3, 0, 2),
}

.hidePasswordWrongText{
  p{
    display: none;
  }
}
.createAccountWrapper {
  background-color: white;
  height: 90vh;
  width: 92%;
  margin: auto;
}