.breadcrumbDiv {
  background: #b0bec5;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  padding-left: 24px;
  height: 30px;
  line-height: 30px;
  margin: 0px;
  border-radius: 0px 0px 18px 18px;
  display: flex;
  align-items: center;
}

.breadCrumbIcon{
  &.MuiSvgIcon-root{
    font-size: 1em;
    margin-right: 3px;
    vertical-align: middle;
  }
}

.MuiTypography-body1.lastElement {
  text-decoration: none;
  color: currentcolor;
  font-size: 13px;
}
.breadCrumbLink {
  text-decoration: none;
  color: white;
  font-size: 13px;
  vertical-align: text-bottom;
}

.innderText {
  margin: 0px;
  margin-left: 8px;
}

.headerIcons{
  font-size: 0.8em;
}