// https://material-ui.com/
@use '@material/button/mdc-button';
@use '@material/button';

.wrapper {
  display: flex;
  color: rgb(68, 68, 68);
  width: 92%;
  margin: auto;

  .mdc-drawer {
    // box-shadow: 6px 0 6px rgba(0, 0, 0, 0.1);
    height: 90vh;
    width: 190px;

    position: sticky;
    top: 60px;
    overflow: hidden;

    a:hover {
      background-color: whitesmoke;
      color: #3f51b5;
    }

    .cursorLink:hover {
      background-color: whitesmoke;
      color: #3f51b5;
      cursor: pointer;
    }

    span {
      margin-left: 16px;
      font-size: 14px;
    }
    .nav-list {
      padding: 8px 0;
      list-style-type: none;
    }
  }
  .mainFrame {
    width: 100%;
    background-color: white;
    display: grid;
  }
}

.documentationContent {
  padding-left: 16px;
  padding-right: 64px;
  height: 90vh;
  padding-bottom: 64px;
  overflow: auto;
  p {
    font-size: 14px;
  }
  div {
    font-size: 14px;
  }
  h3 {
    padding-top: 8px;
  }
}

.blueColor {
  &.MuiButtonBase-root {
    color: #3f51b5;
  }
}
